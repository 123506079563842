import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import cx from 'classnames';

import ActionsTooltip from 'components/Tooltip/ActionsTooltip';
import ActionTooltipOptions from 'data/constants/actionTooltipOptions';
import { Button, ModalConfirm, Table } from '@hometap/htco-components';
import { displayFullDate, parseCreatedAtDateParts } from 'utils/dates';
import { getGraphQLError } from 'utils/errors';
import { showNotification, TOAST_TYPE } from 'utils/toasts';
import { SidebarDetail } from 'apps/track-details/ApplicationReview/components';
import { getTrackDetailPageUrls, getSalesforceOpportunityUrls } from 'apps/track-details/utils/trackDetailsLinks';

import { UPDATE_ACTIVE_OPPORTUNITY } from 'apps/track-details/ApplicationReview/data/mutations/updateActiveOpportunity';
import './OpportunityDetailsSidebarController.scss';

export const OpportunityDetailsSidebarController = ({ track }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [oppToActivateName, setOppToActivateName] = useState();
  const [oppToActivateId, setOppToActivateId] = useState();

  const [updateActiveOpportunity] = useMutation(UPDATE_ACTIVE_OPPORTUNITY);

  const { trackId } = useParams();
  const { currentStage, friendlyId, reviewer, coordinator, underwriter } = track || {};
  const { editExpirationDateUrl } = getTrackDetailPageUrls(trackId);

  const statusFieldDisplay = () => {
    const { inInvested, hasActiveSettlement, activeInvestment, reviewStatus, reviewStatusLastUpdatedDays } =
      track || {};
    if (inInvested || hasActiveSettlement) {
      return (
        <div>
          <span>Post-close Status:</span> <strong>{activeInvestment?.postCloseStatus}</strong>
        </div>
      );
    } else if (reviewStatus) {
      return (
        <div>
          <span>Current Status:</span>{' '}
          <strong>
            {reviewStatus?.label} ({reviewStatusLastUpdatedDays})
          </strong>
        </div>
      );
    }
  };

  const expirationValueDisplay = () => {
    const { hasExpired, canExpire, expirationDate } = track || {};
    const [monthDay, year] = parseCreatedAtDateParts(expirationDate);
    let content, expirationPrefix;

    if (hasExpired) {
      expirationPrefix = 'Expired on ';
      content = (
        <>
          {monthDay}, {year}
        </>
      );
    } else if (canExpire) {
      expirationPrefix = 'Expires on ';
      content = (
        <>
          {monthDay}, {year}
        </>
      );
    } else {
      content = 'No expiration date';
    }
    return (
      <span>
        {expirationPrefix}
        <strong className="SidebarDetailInlineField">
          <ActionsTooltip actions={[{ actionType: ActionTooltipOptions.Edit, onEdit: editExpirationDateUrl }]}>
            {content}
          </ActionsTooltip>
        </strong>
      </span>
    );
  };

  const additionalTracksTable = () => {
    const { additionalTracks } = track || {};
    if (additionalTracks?.length >= 1) {
      const columns = [
        {
          label: 'Address',
          key: 'fullAddress',
        },
        {
          label: 'Stage',
          key: 'currentStage',
        },
        {
          label: 'Friendly id',
          key: 'friendlyId',
        },
      ];
      const data = [];
      for (const additionalTrack of additionalTracks ?? []) {
        const { identifier, fullAddress, currentStage, friendlyId } = additionalTrack || {};
        const { stageUrl } = getTrackDetailPageUrls(identifier);
        data.push({
          id: identifier,
          fullAddress: <a href={stageUrl}>{fullAddress}</a>,
          currentStage: currentStage?.label,
          friendlyId: friendlyId,
        });
      }
      return (
        <div>
          Additional Tracks
          <Table className="additionalTracksTable" compact columns={columns} data={data} />
        </div>
      );
    }
  };

  const handleUpdateActiveOpportunity = opportunityId => {
    updateActiveOpportunity({
      variables: {
        trackId,
        opportunityId,
      },
    })
      .then(() => {
        showNotification({
          type: TOAST_TYPE.success,
          description: "Successfully activated and resync'd the Opportunity.",
        });
      })
      .catch(error => {
        showNotification({
          type: TOAST_TYPE.warning,
          description: getGraphQLError(error),
        });
      });
  };

  const handleModalConfirm = opportunityId => {
    setModalOpen(false);
    handleUpdateActiveOpportunity(opportunityId);
  };

  const OpportunityActivationModal = (opportunityId, opportunityName) => {
    return (
      <div>
        (
        <Button
          className="opportunityDetailsSidebarButton"
          size="small"
          theme="link"
          onClick={() => {
            setOppToActivateName(opportunityName);
            setOppToActivateId(opportunityId);
            setModalOpen(true);
          }}
        >
          make active
        </Button>
        )
        {modalOpen && (
          <ModalConfirm
            className="activateOpportunityModal"
            theme="danger"
            open={modalOpen}
            header="Confirm Activate &amp; Resync"
            confirmText="Activate &amp; Resync"
            onConfirm={() => handleModalConfirm(oppToActivateId)}
            onClose={() => setModalOpen(false)}
          >
            <p>You are about to activate &amp; resync the '{oppToActivateName}' Opportunity in Salesforce.</p>
            <p>
              This means future Portals actions will synchronize data to this Opportunity instead of any of the other
              Opportunities.
            </p>
            <p>Do you want to proceed?</p>
          </ModalConfirm>
        )}
      </div>
    );
  };

  const opportunityRecordsDisplay = () => {
    const opportunitiesArr = [];
    const { opportunityRecords } = track || {};
    if (opportunityRecords?.length === 0) {
      return (
        <div>
          Opp: <strong>No Opportunity Objects Known</strong>
        </div>
      );
    }
    for (const opportunityRecord of opportunityRecords ?? []) {
      const { identifier, url, name, isActive } = opportunityRecord;
      const { editOpportunityNameUrl } = getSalesforceOpportunityUrls(trackId, identifier);
      let opportunityActivation = {};
      if (isActive) {
        opportunityActivation = <div className="opportunityActive">(active)</div>;
      } else {
        opportunityActivation = OpportunityActivationModal(identifier, name);
      }

      opportunitiesArr.push(
        <div key={name}>
          Opp:{' '}
          <strong className="SidebarDetailInlineField">
            <ActionsTooltip
              actions={[
                { actionType: ActionTooltipOptions.Link, toLink: url },
                { actionType: ActionTooltipOptions.Copy, value: url },
                { actionType: ActionTooltipOptions.Edit, onEdit: editOpportunityNameUrl },
              ]}
            >
              {name}
            </ActionsTooltip>
          </strong>
          {opportunityActivation}
        </div>,
      );
    }
    return (
      <div className="SidebarDetailContent">
        {opportunitiesArr}
        {additionalTracksTable()}
      </div>
    );
  };

  const activeOfferAcceptedByDisplay = () => {
    if (!track?.activeOfferAcceptedByDate) {
      return null;
    }

    return (
      <div className={cx({ 'text-red-100': track.isActiveOfferExpired })}>
        {track.isActiveOfferExpired ? 'Offer expired on: ' : 'Offer should be accepted by: '}
        <strong>{displayFullDate(track.activeOfferAcceptedByDate)}</strong>
      </div>
    );
  };

  return (
    <SidebarDetail sidebarDetailTitle={'Opportunity Details'}>
      <div>
        <span>Current Stage:</span> <strong>{currentStage?.label}</strong>
      </div>
      <div>
        Track ID: <strong>{friendlyId}</strong>
      </div>
      {statusFieldDisplay()}
      <div>Expiration Date: {expirationValueDisplay()}</div>
      {activeOfferAcceptedByDisplay()}
      <div>
        Investment Manager: <strong>{reviewer?.fullNameShort}</strong>
      </div>
      <div>
        Application Specialist: <strong>{coordinator?.fullNameShort}</strong>
      </div>
      <div>
        Underwriter: <strong>{underwriter?.fullNameShort}</strong>
      </div>
      {opportunityRecordsDisplay()}
    </SidebarDetail>
  );
};

export default OpportunityDetailsSidebarController;
