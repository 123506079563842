import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const Context = React.createContext({});

export const useSpecificContentQuery = (query, options = {}) => {
  const { trackId } = useParams();
  const queryData = useQuery(query, { variables: { trackId }, ...options, fetchPolicy: 'no-cache' });
  const { setLoading } = useContext(Context);

  useEffect(() => {
    setLoading(queryData.loading);
  }, [queryData.loading, setLoading]);

  return {
    ...queryData,
    trackId,
  };
};

export const useSpecificContent = id => {
  const { loading, specificTasksMap } = useContext(Context);
  return { loading, specificTaskData: (id && specificTasksMap?.[id]) || {}, specificTasksMap };
};

export const useUpdateSpecificTaskContent = id => {
  const { setSpecificTasksMap, loading, setLoading } = useContext(Context);

  const updateSpecificTaskById = useCallback(
    (id, data, isDelete = false) => {
      // while loading specific content, we should not update task data
      if (!id || loading) {
        return;
      }
      if (isDelete) {
        setSpecificTasksMap(prevData => {
          const newData = { ...prevData };
          delete newData[id];
          return newData;
        });
      } else {
        setSpecificTasksMap(prevData => ({ ...prevData, [id]: prevData[id] ? { ...prevData[id], ...data } : data }));
      }
    },
    [setSpecificTasksMap, loading],
  );
  return {
    updateSpecificTaskById,
    setLoading,
  };
};

export const TaskSpecificContentProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  // specificTasksMap stores all additional and necessary data for each saved task
  // to update use useUpdateSpecificTaskContent and to get data useSpecificContent
  const [specificTasksMap, setSpecificTasksMap] = useState({});
  const contextData = { loading, setLoading, specificTasksMap, setSpecificTasksMap };
  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};
