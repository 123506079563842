import React, { useCallback, useContext, useState } from 'react';
import { getIsNewId } from '../sections/LiensAndPaydownsController/utils/liens';

const Context = React.createContext({});

export const useSectionFormById = id => {
  const { sectionFormsMap } = useContext(Context);
  return (id && sectionFormsMap?.[id]) || {};
};

export const useUpdateSectionForm = () => {
  const { setSectionFormsMap, isSaving } = useContext(Context);

  const updateSectionFormById = useCallback(
    (id, data) => {
      // while saving forms, we should not update the data
      if (!id || isSaving || !setSectionFormsMap) {
        return;
      }
      setSectionFormsMap(prevData => ({ ...prevData, [id]: prevData[id] ? { ...prevData[id], ...data } : data }));
    },
    [setSectionFormsMap, isSaving],
  );

  const saveFormOnChangeSection = useCallback(
    id => {
      if (!id || isSaving) {
        return;
      }
      setSectionFormsMap(prevData => ({
        ...prevData,
        [id]: prevData[id] ? { ...prevData[id], prevFormData: prevData[id].formData } : {},
      }));
    },
    [setSectionFormsMap, isSaving],
  );

  const deleteFormById = useCallback(
    id => {
      if (!id || isSaving) {
        return;
      }
      setSectionFormsMap(prevData => {
        const updatedData = { ...prevData };
        if (updatedData[id]) {
          delete updatedData[id];
        }
        return updatedData;
      });
    },
    [setSectionFormsMap, isSaving],
  );

  return {
    isSaving,
    updateSectionFormById,
    deleteFormById,
    saveFormOnChangeSection,
  };
};

const getUpdatedInitialSectionForm = ({ form, newPaydownIdentifier, newLienIdentifier }) => {
  const updatedForm = {
    ...form,
    initialFormData: form.formData,
    isFormChanged: false,
    isFormValid: true,
    prevFormData: undefined,
    showTriggeredPublishErrors: false,
  };

  if (newPaydownIdentifier && newLienIdentifier) {
    updatedForm.formData.identifier = newPaydownIdentifier;
    updatedForm.formData.newLien = false;
    updatedForm.lienId = newLienIdentifier;
  }

  return updatedForm;
};

export const SECTION_ALERT_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
};

const SELECT_ALERT_TYPE_TO_ICON_AND_COLOR = {
  [SECTION_ALERT_TYPES.ERROR]: {
    icon: 'icon-error',
    color: '#b41a1a',
  },
  [SECTION_ALERT_TYPES.WARNING]: {
    icon: 'icon-alert',
    color: '#936C00',
  },
};

/**
 * @typedef DBRecordAndForm
 * @type {object}
 * @property {object} [dbRecord] - The database record coming via GraphQL
 * @property {object} [form] - The form with includes the form data and other form related properties
 * @property {object} [childId] - The child database record id if one exists
 * @property {object} [parentId] - The parent database record id if one exists
 */

/**
 * @typedef SelectionLevelAlertDefinition
 * @type {object}
 * @property {string} icon - The icon to display at both the section-level & on each of the form tabs
 * @property {string} message - The section-level message
 * @property {(idToDBRecordAndForm: Record<string, DBRecordAndForm>) => string[]} getTriggeringIds - A function that returns the ids of the forms that trigger the alert
 */

/**
 * @typedef SelectionLevelAlert
 * @type {object}
 * @property {string} icon - The icon to display at both the section-level & on each of the form tabs
 * @property {string} message - The section-level message
 * @property {string[]} triggeringIds - The ids of the forms that trigger the alert
 */

/**
 * @typedef UseFormsForAppReviewSectionReturn
 * @type {object}
 * @property {() => SelectionLevelAlert|null} getHighestPrioritySectionAlert - Get the highest priority alert that has been triggered, else null
 * @property {boolean} isValid - Are all forms valid?
 * @property {boolean} isChanged - Have any forms been changed?
 * @property {boolean} isSaving - Is the section saving?
 * @property {function} confirmSection - Publish section changes
 * @property {function} cancelSectionChanges - cancel section changes
 * @property {function} deleteSectionLienPaydown - delete section lien paydown (TODO - Abstract into settings)
 * @property {function} savePrevFormDataById - idk Nastya would probably know
 */

/**
 * Creates and returns an instance of a SelectionLevelAlert.
 *
 * @param {SelectionLevelAlertDefinition} alertDef - The definition of the alert to create
 * @param {object} [details] - Optional details of the alert (isTriggered, triggeringIds)
 * @returns {SelectionLevelAlert} - The created alert
 */
export function createSelectionLevelAlert(alertDef, details = {}) {
  const alert = {
    ...alertDef,
    ...SELECT_ALERT_TYPE_TO_ICON_AND_COLOR[alertDef.type],
    ...details,
  };
  delete alert.getTriggeredDetails;
  return alert;
}

/**
 * @param {() => void} params.afterPublishSection Asyncronous callback after publishing section chagnes
 * @param {SelectionLevelAlertDefinition[]} params.sectionAlertDefinitionsInPriorityOrder List of section alert (warnings or errors) definitions in the order of priority.
 *    Section alerts appear underneath the Cancel & Publish buttons for a given section. The tiggering ids reflect the tabs with alert icons.
 * @returns {UseFormsForAppReviewSectionReturn}
 */
export const useFormsForAppReviewSection = ({ afterPublishSection, sectionAlertDefinitionsInPriorityOrder }) => {
  // sectionFormsMap is a mapping of identifiers to form data wrappers
  const { sectionFormsMap = {}, isSaving, setSectionFormsMap, setIsSaving } = useContext(Context);

  /**
   * A custom React hook that returns a function to cancel all changes in the forms made.
   *
   * @param {string} id - The ID of the current opened section form to reset changes for.
   * @returns {Function} - A function that cancels all changes made to the forms.
   */
  const onCancelChanges = useCallback(
    id => {
      const initialSectionFormsMap = Object.entries(sectionFormsMap).reduce((acc, [key, form]) => {
        if (getIsNewId(key)) {
          return acc;
        }
        if (form.isFormChanged) {
          if ((key === id || form.lienId === id) && form.resetData) {
            form.resetData();
          }
          acc[key] = {
            ...form,
            isFormChanged: false,
            isFormValid: true,
            isDeleted: false,
            prevFormData: form.initialFormData,
            formData: form.initialFormData,
            showSubmitErrors: false,
            showTriggeredPublishErrors: false,
          };
        } else {
          acc[key] = form;
        }
        return acc;
      }, {});
      setSectionFormsMap(initialSectionFormsMap);
    },
    [sectionFormsMap, setSectionFormsMap],
  );

  const onPublishSection = useCallback(async () => {
    setIsSaving(true);
    const initialSectionFormsMap = {};
    for (const [key, form] of Object.entries(sectionFormsMap)) {
      if (form.isFormChanged && !form.isDeleted) {
        if (form.complete) {
          // we need to do mutations sequentially otherwise it will not update all different
          // TODO - https://hometap.atlassian.net/browse/EH-565 Clean-up to `form.complete` signature
          // eslint-disable-next-line no-await-in-loop
          const { newLienIdentifier } = (await form.complete({ formData: form.formData })) || {};
          initialSectionFormsMap[key] = getUpdatedInitialSectionForm({ form });

          // create new paydown when creating new lien
          if (getIsNewId(form.formData.identifier) && form.paydownId && newLienIdentifier) {
            const paydownForm = sectionFormsMap[form.paydownId];
            if (paydownForm && paydownForm.complete) {
              const { newPaydownIdentifier } =
                // we need to do mutations sequentially
                // eslint-disable-next-line no-await-in-loop
                (await paydownForm.complete({ formData: paydownForm.formData, newLienId: newLienIdentifier })) || {};
              initialSectionFormsMap[form.paydownId] = getUpdatedInitialSectionForm({
                form: paydownForm,
                newPaydownIdentifier,
                newLienIdentifier,
              });
              if (newPaydownIdentifier) {
                initialSectionFormsMap[key].paydownId = newPaydownIdentifier;
              }
            }
          }
          // the order is important to correctly update newLienIdentifier when we create new lien and new paydown
          if (newLienIdentifier) {
            initialSectionFormsMap[key].formData.identifier = newLienIdentifier;
            initialSectionFormsMap[key].formData.newLien = false;
          }
        }
      }
    }
    setSectionFormsMap(initialSectionFormsMap);
    if (afterPublishSection) {
      afterPublishSection();
    }
    setIsSaving(false);
  }, [setIsSaving, setSectionFormsMap, sectionFormsMap, afterPublishSection]);

  const savePrevFormDataById = useCallback(
    id => {
      if (!id || isSaving) {
        return;
      }
      setSectionFormsMap(prevData =>
        prevData[id]
          ? {
              ...prevData,
              [id]: { ...prevData[id], prevFormData: prevData[id].formData },
            }
          : prevData,
      );
    },
    [setSectionFormsMap, isSaving],
  );

  const deleteExistingPaydownForm = useCallback(
    // This callback accepts the deletePaydown callback function as an argument due to paydown forms not
    // being included in the initial section state.
    async (paydownId, lienId, deletePaydown, afterDeleteLienPaydown) => {
      if (!paydownId || isSaving) {
        return;
      }

      if (deletePaydown) {
        const lienForm = sectionFormsMap[lienId];
        const isOk = await deletePaydown(paydownId);

        if (isOk) {
          const updatedSectionFormsMap = { ...sectionFormsMap };
          if (lienForm) {
            afterDeleteLienPaydown?.();
            const updatedFormData = { ...lienForm.formData, paydown: null };
            updatedSectionFormsMap[lienId] = {
              ...lienForm,
              formData: updatedFormData,
              initialFormData: { ...lienForm.initialFormData, paydown: null },
              // We always need to update the prevFormData data to prevent any loss of changes made in the lien form.
              prevFormData: updatedFormData,
              paydownId: undefined,
            };
          }
          delete updatedSectionFormsMap[paydownId];
          setSectionFormsMap(updatedSectionFormsMap);
        }
      }
    },
    [sectionFormsMap, setSectionFormsMap, isSaving],
  );

  // Get Highest Priority Section Alert
  const getHighestPrioritySectionAlert = ({ dbRecords, childRecordKey = undefined }) => {
    // Create a OUTER JOIN of items and forms
    const idToDBRecordAndForm = {};
    // Iterate over the db records and fill in the map with those forms
    dbRecords?.forEach(dbRecord => {
      // Grab the child db record if it exists
      const id = dbRecord.identifier;
      const child = childRecordKey && dbRecord[childRecordKey];
      const childId = child?.identifier;
      // Add the db record to the map so it will appear alongside its form
      idToDBRecordAndForm[id] = { dbRecord, childId };
      if (childId) {
        // If the child db record exists add it to the map so it will appear alongside its form
        idToDBRecordAndForm[childId] = { dbRecord: child, parentId: id };
      }
    });
    // Iterate over the forms and fill in the map with those forms
    Object.entries(sectionFormsMap)
      .filter(form => !form.isDeleted)
      .forEach(([key, form]) => {
        idToDBRecordAndForm[key] = idToDBRecordAndForm[key] ? { ...idToDBRecordAndForm[key], form } : { form };
      });
    // Iterate over idToDBRecordAndForm to add some useful utility functions
    Object.values(idToDBRecordAndForm).forEach(dbRecordAndForm => {
      // Get the value from the form if it exists, otherwise use the dbRecord. The form has priority because it is the most up-to-date.
      dbRecordAndForm.getCurrentValue = key => {
        if (dbRecordAndForm.form) {
          return dbRecordAndForm.form?.formData?.[key];
        }
        return dbRecordAndForm.dbRecord?.[key];
      };
    });

    // Find the first alert that has been triggered
    for (const alertDef of sectionAlertDefinitionsInPriorityOrder) {
      // Get the ids of the forms/dbRecords that trigger the alert
      const details = alertDef.getTriggeredDetails(idToDBRecordAndForm);
      if (details?.isTriggered) {
        // Short-circuit if we have found our triggering ids
        return createSelectionLevelAlert(alertDef, details);
      }
    }
    return null;
  };

  /**
   * Updates `sectionFormsMap` to control the visibility of publish errors based on form validity and lien report field activity.
   * - Errors are always shown for invalid forms.
   * - Errors are hidden for forms with `isValidForm` undefined.
   * - For valid forms, errors are shown if the corresponding lien report field is active.
   */
  const triggerPublishErrors = useCallback(
    lienReportFieldsTouched => {
      let hasTriggeredErrors = false;
      const sectionFormsMapEntries = Object.entries(sectionFormsMap);

      const hasChangedSectionForms =
        sectionFormsMapEntries?.some(([_, form]) => form.isFormChanged || form.formData?.newLien) ?? false;

      const updatedSectionFormsMap = sectionFormsMapEntries.reduce((acc, [key, form]) => {
        const showTriggeredPublishErrors = form.formData
          ? !form.isValidForm ||
            (form.isValidForm &&
              lienReportFieldsTouched[key]?.active &&
              Object.keys(lienReportFieldsTouched[key]).length === 1)
          : false;
        const showTriggeredTypeError = !form.formData;
        if (!hasTriggeredErrors && (showTriggeredPublishErrors || showTriggeredTypeError)) {
          hasTriggeredErrors = true;
        }
        acc[key] = {
          ...form,
          showTriggeredPublishErrors,
          showTriggeredTypeError,
        };

        return acc;
      }, {});

      setSectionFormsMap(updatedSectionFormsMap);
      return { hasTriggeredErrors, breakPublish: !hasChangedSectionForms };
    },
    [sectionFormsMap, setSectionFormsMap],
  );

  const forms = Object.values(sectionFormsMap);

  return {
    getHighestPrioritySectionAlert,
    isValid: forms?.every(form => form.isValidForm) ?? false,
    isChanged: forms?.some(form => form.isFormChanged) ?? false,
    isSaving,
    confirmSection: onPublishSection,
    cancelSectionChanges: onCancelChanges,
    deleteSectionLienPaydown: deleteExistingPaydownForm,
    savePrevFormDataById,
    sectionFormsMap,
    setSectionFormsMap,
    triggerPublishErrors,
  };
};

export const SectionFormsProvider = ({ children }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [sectionFormsMap, setSectionFormsMap] = useState({});
  const contextData = {
    isSaving,
    setIsSaving,
    sectionFormsMap,
    setSectionFormsMap,
  };
  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};
