import dayjs from 'dayjs';

/** This will change the timezone of the datetime provided to the local timezone of the user
 *  Because of that it could change the actual date that's displayed
 */
export const parseCreatedAtDateParts = createdAt => {
  const date = new Date(createdAt);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  };
  return date.toLocaleDateString('en', options).split(',');
};

export const formatISODate = date => {
  // dates is in yyyy-mm-dd format with no timezone coerces the timezone info to
  // 00:00:00 GMT. Splitting the date allows new Date() to parse solely the year, month, and day
  // only instead of coercing it to the GMT timezone.
  const splitDate = new Date(date.split('-'));
  return parseCreatedAtDateParts(splitDate);
};

export const getAPStyleTime = (time = '') => time.replace('AM', 'a.m.').replace('PM', 'p.m.');

export const getDateTimeFormat = (date, dateFormat = 'MM/DD/YYYY') => dayjs(date).format(`${dateFormat}, h:mm A`);

/**
 * @param {string | number | Date} date - The date to format
 * @return {string} The date in 'MMM DD, YYYY' format
 *
 * @example "Jun 3, 2024"
 */
export const formatMonthDayYear = date => {
  return dayjs(date).format('MMM DD, YYYY');
};

/**
 * @param {string | number | Date} date - the date to format
 * @returns {string} the date in 'MMM DD, YYYY h:mm A' format
 *
 * @example "Jun 3, 2024 12:00 PM"
 */
export const formatMonthDayYearTime = date => {
  return dayjs(date).format('MMM DD, YYYY h:mm A');
};

/**
 * @param {string | number | Date} date - The date to format
 * @return {string} The date in 'MMMM D, YYYY' format
 *
 * @example "June 3, 2024"
 */
export const formatFullMonthDayYear = date => {
  return dayjs(date).format('MMMM D, YYYY');
};

/**
 * @param {string | number | Date} date - The date to format
 * @return {string} The date in 'ddd MMM D YYYY' format
 *
 * @example "Mon Jun 3 2024"
 */
export const formatDayOfWeekMonthDayYear = date => {
  return dayjs(date).format('ddd MMM D YYYY');
};

/**
 * @param {string | number | Date} date - The date to format
 * @return {string} The date in 'MM/DD/YYYY' format
 *
 * @example "06/03/2024"
 */
export const formatMMDDYYYY = date => {
  const formattedDate = dayjs(date);
  return formattedDate.isValid() ? formattedDate.format('MM/DD/YYYY') : null;
};

/**
 * @param {string | number | Date} date - The date to format
 * @return {string} The date in 'YYYY-MM-DD' format
 *
 * @example "2024-06-03"
 */
export const formatYYYYMMDDWithDashes = date => {
  return dayjs(date).format('YYYY-MM-DD');
};

// dayjs returns an immutable object so it should be safe to pass `dayjs()` as a default parameter
export const getDaysUntil = (date, startDate = dayjs()) => Math.round(dayjs(date).diff(startDate, 'days', true));
export const getDaysSince = date => Math.round(dayjs(dayjs()).diff(date, 'days', true));

export const getHumanReadableDaysSince = date => {
  const daysSince = getDaysSince(date);
  if (daysSince === 0) {
    return 'Today';
  }

  if (daysSince === 1) {
    return '1 day ago';
  }

  return `${daysSince} days ago`;
};

// dayjs returns a string of the date of MM-DD-YYYY h:mm:A of todays date
export const getToday = () => dayjs().format('MM-DD-YYYY h:mm:A');

export const isExpired = date => {
  const daysSince = getDaysSince(date);
  return daysSince > 0;
};

/** use to know the expires in days and is expired information
 * @param {dayjs.ConfigType?} value
 * @param {number?} expirationDays
 * @returns {{expiresIn?: number, isExpiring?: boolean}|undefined}
 */
export const getExpirationDateData = (value, expirationDays = EXPIRATION_DAYS_COUNT) => {
  if (!value) {
    return { expiresIn: undefined, isExpired: undefined };
  }
  const expiry = dayjs(value);
  const today = dayjs().startOf('day');
  const expiresIn = expiry.diff(today, 'day');
  return { expiresIn, isExpiring: expiresIn <= expirationDays };
};

export const EXPIRATION_DAYS_COUNT = 30;

/** use to know the date with a specified amount of days
 * @param {number} [daysToAdd]
 * @param {string} [format]
 * @returns {string}
 */
export const getTheDateWithDaysAdded = (daysToAdd = 1, format = 'YYYY-MM-DD') =>
  dayjs().add(daysToAdd, 'day').format(format);

/**
 * used to add only business days to a date
 * @param {number} [businessDaysToAdd]
 * @param {date} [startDate]
 * @returns {date}
 */
export const addBusinessDays = (businessDaysToAdd, startDate = dayjs()) => {
  let calcDate = startDate;
  let count = 0;

  while (count < businessDaysToAdd) {
    calcDate = calcDate.add(1, 'day'); // Increment calcDate by 1 day on each iteration
    const dow = calcDate.get('day'); // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)

    if (dow !== 0 && dow !== 6) {
      // Check if it's not a weekend
      count++; // Only increment count on business days
    }
  }

  return calcDate;
};

export const helperText = 'Requires MM/DD/YYYY format';

export const displayFullDate = date => (dayjs(date).isValid() ? dayjs(date).format('MMM D, YYYY') : 'Invalid date');
